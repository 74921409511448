import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'react-bootstrap'
import { postRequest } from '/src/httpClient'
import { Button, Textarea, TextInput } from '/src/components/common'

import './contact_form.scss'

export const ContactForm = () => {
  const [message, setMessage] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = (data) => {
    postRequest('/api/enquiry/create', data).then(response => {
      if (response) {
        setMessage('Thank you for your enquiry.')
      }
    })
  }

  if (message) {
    return (
      <div className="contact-form-container">
        <Row className="form-message">
          <Col sm={12}>
            <label>{message}</label>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="contact-form-container">
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={6}>
            <TextInput 
              label="Name"
              error={errors.name}
              register={register('name', {
                placeholder: 'Name',
                required: true,
              })} />
          </Col>
          <Col sm={6}>
            <TextInput 
              label="Email"
              error={errors.email}
              register={register('email', {
                placeholder: 'Email',
                required: true,
                pattern: {
                  value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                  message: 'Please provide a valid email address'
                }
              })} />
          </Col>
          <Col sm={6}>
            <TextInput 
              label="Phone"
              error={errors.phone}
              register={register('phone', {
                placeholder: 'Phone',
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please provide a valid phone number'
                }
              })} />
          </Col>
          <Col sm={6}>
            <TextInput 
              label="Postcode"
              error={errors.postcode}
              register={register('postcode', {
                placeholder: 'Postcode',
                required: true,
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: 'Please provide a valid postcode'
                }
              })} />
          </Col>
          <Col sm={12}>
            <Textarea 
              label="Enquiry"
              register={register('enquiry', {
                required: true,
              })} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button variant="primary" type="submit">
              Send Enquiry
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default ContactForm
