import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { ContactForm } from '/src/components/contact'

const ContactPage = () => {
  return (
    <Layout hero={<HeroImage title="Contact us" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Metalcorp is an Australian steel distribution business that is into everything in steel.
                Our extensive product range includes livestock handling equipment, building materials, structural and tubular steel, fencing, roofing, aluminium and more.
                We pride ourselves on delivering the highest levels of customer service and quality products. We have huge stock holdings and our products can be cut to length and delivered to your site.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <ContactForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const Head = () => {
  return (
    <>
      <title>Contact | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

